<template>
    <div>欢迎来到哈工精密后台管理系统</div>
</template>

<script>
    export default {
        name: "Dashboard",
        data(){
            return{
                //
            }
        },
    }
</script>

<style scoped>

</style>